@font-face {
  font-family: 'kfw';
  font-style: normal;
  font-weight: 400;
  src: local('kfw'), local('kfw'), url('assets/fonts/kfw_font.woff2') format('woff2'),
    url('assets/fonts/kfw_font.woff') format('woff');
}

@font-face {
  font-family: 'kfwRegular';
  font-style: normal;
  src: local('kfwRegular'), local('kfwRegular'),
    url('assets/fonts/kfw_centro_sans_regular/KFWCentroSans-Reg.woff2') format('woff2'),
    url('assets/fonts/kfw_centro_sans_regular/KFWCentroSans-Reg.woff') format('woff');
}

@font-face {
  font-family: 'kfwMedium';
  font-style: normal;
  src: local('kfwMedium'), local('kfwMedium'),
    url('assets/fonts/kfw_centro_sans_medium/KFWCentroSans-Med.woff2') format('woff2'),
    url('assets/fonts/kfw_centro_sans_medium/KFWCentroSans-Med.woff') format('woff');
}

@font-face {
  font-family: 'kfwBold';
  font-style: normal;
  src: local('kfwBold'), local('kfwBold'),
    url('assets/fonts/kfw_centro_sans_bold/KFWCentroSans-Bold.woff2') format('woff2'),
    url('assets/fonts/kfw_centro_sans_bold/KFWCentroSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'kfw-icons';
  font-weight: normal;
  font-style: normal;
  src: local('kfw-icons'), local('kfw-icons'), url('assets/fonts/kfw-icons.woff2') format('woff2'),
    url('assets/fonts/kfw-icons.woff') format('woff');
}

html {
  font-family: 'kfw', 'kfwMedium';
  /* here you are defining base for rem unit */
  font-size: 10px;
}
/* padding and overflow are added by dropdown */
body {
  position: relative;
  min-height: 100vh;
  height: auto;
  padding: 0 !important;
  overflow: auto !important;
}

main {
  min-height: 100vh;
  height: auto;
  padding-bottom: 100px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Prohibit overflowing labels on very small phones */
@media (max-width: 380px) {
  label.MuiInputLabel-outlined {
    width: 80%;
    text-align: left;
  }
}

input {
  border: none !important;
  font-size: 1.6rem !important;
}

.tooltip {
  border: none !important;
  background-color: white !important;
  padding: 0 !important;
  margin-left: -1em !important;
  margin-top: 0 !important;
  height: 0 !important;
  position: relative !important;
}

.header {
  justify-content: flex-start !important;
}

span[class*='colorSecondary'] {
  color: #60800f !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f3f1e7 !important;
}
